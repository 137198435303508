import React from 'react';
import NavigationBar from '../../components/NavigationBar'
import HolidayMessage from '../../components/HolidayMessage'
import Iframe from 'react-iframe';
import { Button, Icon, Divider, Grid, Segment, Header, Responsive } from 'semantic-ui-react';
import '../../assets/styles.css';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer'

const mapAPIURL = 'https://www.google.com/maps/embed/v1/place?key='
const API_KEY = 'AIzaSyBmMpPd3_6YCY_VlIliOMPfmTazVa3-ahE'
const locationQuery = '&q=Easy+Buffet,Belleville+IL'
const orderLink = 'https://order.easybuffet.net/';

// const lunchPrice = '$8.29';
// const dinnerPrice = '$11.29';
// const weekendPrice = '$11.99';
const lunchPrice = '';
const dinnerPrice = '';
const weekendPrice = '';
/**
 * TODO: Add Content related to our restaurant such as Pricing for Buffet, Address, Hours, Map Location
 */

export default class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: false
    }
  }

  toggleModal() {
    this.setState({
      order: !this.state.order
    })

  }

  render() {
    const mapDesktopIFrame = <Iframe
      url={mapAPIURL + API_KEY + locationQuery}
      width='100%'
      height='450vh'
      className='roundElement'
    />;
    const mapMobileIFrame = <Iframe
      url={mapAPIURL + API_KEY + locationQuery}
      width='100%'
      height='250vh'
      className='roundElement'
    />;

    const businessAddress = <Segment>
      <Header>
        Information about Easy Buffet and Grill
      </Header>
      <Divider />

      2524 Green Mount Commons Drive <br />
      Suite #120 <br />
      Belleville, IL 62221<br />
      <p>
        <b>Phone Number: <a class='no-link-color' href='tel:+1618-277-3888'>(618)-277-3888</a></b></p>
    </Segment>;

    const businessHours =
      <Segment>
        <Header>
          Business Hours
      </Header>
        <Divider />

        <p><Header sub>Sunday - Thursday </Header>
          11:00 AM  -  9:00 PM</p><p>
          <Header sub> Friday and Saturday</Header>
          11:00 AM  -  9:30 PM
        </p>
      </Segment>;
    ;
    const bottomDetails = <>
      <Segment>
        <Header sub>Lunch Buffet: </Header>
        <b>{lunchPrice}</b>
        <Divider />
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <br /><br />
        </Responsive>
        Monday - Friday <br /> 11:00 AM - 3:30 PM
          </Segment>

      <Segment>
        <Header sub>Dinner Buffet:

           </Header>
        <b>{dinnerPrice} </b>
        <Divider />
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <br /><br />
        </Responsive>
        Monday - Thursday <br /> 3:30 PM - 9:00 PM
          </Segment>
      <Segment>
        <Header sub>Weekend Buffet: </Header>
        <b>{weekendPrice} </b>
        <Divider />

        Friday <br /> 3:30 PM - 9:30 PM <br />
        Saturday <br /> 11:00 AM - 9:30 PM <br />
        Sunday <br /> 11:00 AM - 9:00 PM
          </Segment></>;

    const bottomDetailsDesktop = <Segment>
      <Header>
        Buffet Hours
        </Header>
      <Segment.Group horizontal raised>
        {bottomDetails}
      </Segment.Group>
      <b>Senior, Active Military, and Children Buffet Dine-in Discount Available.</b>

    </Segment>;
    const bottomDetailsMobile = <Segment>
      <Header>
        Buffet Hours
    </Header>
      {bottomDetails}
      <b>Senior, Active Military, and Children Buffet<br /> Dine-in Discount Available.</b>
    </Segment>;



    return (
      <div className='App-background'>
        <Helmet>
          <title>Location and Hours - Easy Buffet & Grill</title>
        </Helmet>
        <NavigationBar currentPage='location' order={this.state.order} toggleModal={() => this.toggleModal()}/>
          <div className='pagecontent'>
          <Button color='facebook' circular size='massive' href={orderLink} target="_blank">
                        <Icon name='food' />
                        Order Now!
            </Button>
            <br/> <br/>
          <HolidayMessage />
            
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>


            <Grid divided='vertically'>
              <Grid.Row columns={2} stretched>
                <Grid.Column stretched>
                  <Grid divided='vertically'>
                    <Grid.Row columns={2} stretched>
                      <Grid.Column stretched width={10}>
                        {businessAddress}
                      </Grid.Column>
                      <Grid.Column stretched width={6}>
                        {businessHours}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {bottomDetailsDesktop}
                </Grid.Column>
                <Grid.Column stretched>
                  {mapDesktopIFrame}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Responsive>

          <Responsive {...Responsive.onlyMobile} >
            {businessAddress}

            {mapMobileIFrame}
            {bottomDetailsMobile}
            {businessHours}

          </Responsive>
        </div>

        <Footer currentPage='location' />
      </div>
    );
  }
}

